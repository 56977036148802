<template lang="pug">
.container
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(title='Reglas de odometro')
  .card
    .card-content
      app-form(@submit='submit' :is-saving='isSaving' :horizontal='false')
        rule-base.mb-3(v-model='ruleBase')
        .card.mb-3
          .card-content
            div
              b-radio(v-model='odometerRule.type' name='type' :native-value='ruleOdometerTypeEnum.OneTime' @input='typeChanged') Una sola vez
              b-radio(v-model='odometerRule.type' name='type' :native-value='ruleOdometerTypeEnum.Repetitive' @input='typeChanged') Repetitivo
            div.mt-5(v-if='typeIsSelected')
              text-field.mt-4(:label='valueLabel' placeholder='10000' type='number' required v-model='odometerRule.value')
              text-field.mt-4(label='A partir de los (valor en kilometros)' placeholder='25000' type='number' required v-model='odometerRule.start' v-if='odometerRule.type === ruleOdometerTypeEnum.Repetitive')
              text-field.mt-4(label='Cuántas veces: (Cero es indefinido)' placeholder='0' type='number' required v-model='odometerRule.times' v-if='odometerRule.type === ruleOdometerTypeEnum.Repetitive' min='0')
              text-field.mt-4(label='Mensaje de la notificación' placeholder='Ejm: Es momen to de cambiar aceite' :maxlength='50' required v-model='odometerRule.message')
        rule-output.mb-3(v-model='ruleOutputs')
</template>

<script>
import { ruleTypeEnum, ruleOdometerTypeEnum } from '@/constants/enums'
import toastService from '@/services/toast.service'
import rulesService from '@/services/rules.service'

import RuleBase from './blocks/RuleBase'
import RuleOutput from './blocks/RuleOutput'
import { rulesList } from '@/router/routes'

export default {
  components: { RuleBase, RuleOutput },
  data () {
    return {
      ruleOdometerTypeEnum,
      ruleBase: {
        type: ruleTypeEnum.Odometer,
        name: '',
        devices: []
      },
      ruleOutputs: {},
      odometerRule: {
        message: ''
      },
      isLoading: false,
      isSaving: false
    }
  },
  created () {
    this.getData()
  },
  computed: {
    typeIsSelected () {
      return this.odometerRule.type || this.odometerRule.type === 0
    },
    valueLabel () {
      if (this.odometerRule.type === ruleOdometerTypeEnum.OneTime) {
        return 'La regla se activará a los (Valor en Kilometros)'
      } else return 'Cada... (Kilometros)'
    }
  },
  methods: {
    async getData () {
      this.isLoading = true

      if (this.$route.params.id) {
        const promises = [rulesService.getOne(this.$route.params.id)]
        const [ruleResult] = await Promise.all(promises)
        if (ruleResult) {
          const { id, type, name, devices, emailActions, generateEvent, turnOffEngine, odometerRules } = ruleResult
          this.ruleBase = { id, type, name, devices }
          this.odometerRule = odometerRules[0] || {}
          this.ruleOutputs = { emailActions, generateEvent, turnOffEngine }
        }
      }
      this.isLoading = false
    },
    typeChanged () {
      if (this.odometerRule.type === ruleOdometerTypeEnum.OneTime) {
        this.odometerRule.start = null
        this.odometerRule.times = null
      }
    },
    async submit () {
      const odometerRules = [this.odometerRule]
      const rule = {
        ...this.ruleBase,
        ...this.ruleOutputs,
        odometerRules
      }
      this.isSaving = true
      const result = await rulesService.save(rule)
      if (result) {
        toastService.show('Regla guardada')
        this.$router.push({ name: rulesList.name })
      }
      this.isSaving = false
    }
  }
}
</script>
